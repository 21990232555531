<!--
 * @Description: history 导航菜单
 * @Author: heqi
 * @Date: 2022-07-20 11:03:15
 * @LastEditTime: 2022-09-09 09:27:17
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\views\layout\work\okr\myokr\OkrHistoryNav.vue
-->
<template>
  <el-affix :offset="71">
    <div class="okr-history-nav scroll-bar">
      <div class="cursor-pointer" @click="back"><el-icon><ArrowLeftBold /></el-icon>返回本期目标</div>
      <div :class="['cursor-pointer', 'dep-item', 'oneline-overflow', item.depId == currentActive ? 'active' : '' ]" v-for="item in depList" :key="item.id" @click="changeDep(item)">
      <img v-if="item.headerImg" :src="item.headerImg" :alt="item.navName" />
      {{item.depName}}
      </div>
    </div>
  </el-affix>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { watch, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      depList: [],
      currentActive: '麦风科技',
      departmentList: computed(() => store.state.okr.departmentList)
    })

    watch(() => route.query.depId, (newValue) => {
      // console.log(newValue)
      state.currentActive = newValue
    }, { immediate: true })

    onMounted(() => {
      const tempArr = []
      // console.log(state.departmentList)
      state.departmentList.forEach(i => {
        tempArr.push({
          depName: i.name,
          depId: i.dept_id,
          selected: false,
          child: true,
          routerName: 'myokr',
          routerId: i.dept_id,
          headerImg: i.user_avatar ? i.user_avatar : undefined
        })
      })
      state.depList = tempArr
    })
    const changeDep = ({ depName, depId }) => {
      state.currentActive = depId
      router.push({ name: 'okrHistory', query: { department: depName, depId: depId } })
    }

    const back = () => {
      let firstDep
      // console.log(state.depList)
      if (route.query.originDepId) {
        firstDep = state.depList.filter(i => Number(i.depId) === Number(route.query.originDepId))[0]
      } else {
        firstDep = state.depList.filter(i => Number(i.depId) === Number(state.currentActive))[0]
      }
      router.push({ name: 'myokr', params: { id: firstDep.depId, depName: firstDep.depName, queryDate: route.query.date ? JSON.stringify(route.query.date) : undefined } })
    }
    return {
      ...toRefs(state),
      changeDep,
      back
    }
  }
}
</script>

<style lang="less">
.okr-history-nav {
  width: 157px;
  height: fit-content;
  padding: 14px 22px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  img {
    margin-right: 4px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    vertical-align: bottom;
  }
  div {
    font-size: 14px;
    height: 18px;
    line-height: 18px;
    color: #333333;
  }
  div:first-child {
    margin-bottom: 14px;
  }
  .el-icon {
    margin-right: 5px;
    font-size: 12px;
    vertical-align: middle;
    transform: translateY(-1px);
  }
  .dep-item {
    width: 125px;
    height: 30px;
    padding: 6px 0 6px 18px;
    border-radius: 28px;
    margin-bottom: 8px;
  }
  .dep-item:hover {
    background: rgba(121, 114, 240, 0.2);
    color: @active-text-color;
  }
  .active {
    color: @active-text-color !important;
  }
}
</style>
